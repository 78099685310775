
import './App.css';
import {useState,useEffect}from "react";
import Axios from 'axios';


function App() {

  const[artistName, setArtistName]=useState("");
  const[albumName, setAlbumName]=useState("");
  const[trackNumber, setTrackNumber]=useState(0);
  const[trackTitle, setTrackTitle]=useState("");
  const[listOfMusic, setListOfMusic]=useState([]);


  const addMusic=()=>{
      Axios.post('https://vybez-africa.herokuapp.com/addmusic',{artistName: artistName,
      albumName:albumName,
      trackNumber:trackNumber,
      trackTitle:trackTitle
      }).then(()=>{
        setListOfMusic([...listOfMusic,{artistName:artistName,albumName:albumName,
        trackNumber:trackNumber,trackTitle:trackTitle}])
      })
    };
    const updateMusic=(id)=>{
      const newartistName=prompt("enter new artist name");

      Axios.put('https://vybez-africa.herokuapp.com/update', {newartistName:newartistName,id:id})
    };
    useEffect(()=>{
      Axios.get('https://vybez-africa.herokuapp.com/read')
      .then((response)=>{
      setListOfMusic(response.data)
      }).catch(()=>{
      console.log("ERR")
      });
      
    },[])
    return (
    <div className="App">
    <div className="inputs">

    <input type="text" placeholder='Artist Name' onChange={(event)=>{setArtistName(event.target.value)}}/>
    <input type="text" placeholder='Album name' onChange={(event)=>{setAlbumName(event.target.value)}}/>
    <input type="number" placeholder='Track number' onChange={(event)=>{setTrackNumber(event.target.value)}}/>
    <input type="text" placeholder='Track title' onChange={(event)=>{setTrackTitle(event.target.value)}}/>
    
    
    <button onClick={addMusic}>Add song</button>
    </div>
    <div>
    {listOfMusic.map((val)=>{
      return(
        <div>
        <div className="music"> 
        <h3>Artist Name:{val.ArtistName}</h3><h3>Album Name:{val.AlbumName}</h3>
        <h3>Track Number:{val.trackNumber}</h3><h3>Track Title:{val.trackTitle}</h3>
        </div>
        <button onClick={()=>{
          updateMusic(val._id);
        }}>update</button>
        <button id='removeBtn'>X</button>
        </div>
        );

    })}
    </div>
    </div>
  );
}

export default App;
 